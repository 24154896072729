import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ReactMarkdown from 'react-markdown'
const FenceOptionsPage = ({ data }) => {
    return (
        <Layout>
            <div className="max-w-6xl mx-auto bg-white drop-shadow-xl border-t-7 md:px-8 px-4 pb-8 mt-8">
                <h2 className="text-3xl text-gray-600 uppercase font-light title pb-5 mt-7 text-center mb-10">FENCE OPTIONS</h2>
                <ReactMarkdown
                    children={data?.markdownRemark?.frontmatter?.content}
                    components={{
                        p: ({ children }) => {
                            return (
                                <p className="font-light text-sm text-gray-600 mb-12 max-w-full">
                                    {children}
                                </p>
                            )
                        },
                        h2: ({ children }) => {
                            return (
                                <h2 className='text-2xl font-bold font3 mb-4'>
                                    {children}
                                </h2>
                            )
                        },
                        h3: ({ children }) => {
                            return (
                                <h3 className="md:text-3xl text-xl text-gray-600 uppercase mt-5 font-medium title pb-5 mt-7 text-center mb-10 ">
                                    {children}
                                </h3>
                            )
                        },
                        h4: ({ children }) => {
                            return (
                                <h4 className='text-2xl font-bold font3 mb-4'>
                                    {children}
                                </h4>
                            )
                        },
                        ul: ({ children }) => {
                            return (
                                <ul className="md:mb-16 mb-6 pl-2 list-disc">
                                    {children}
                                </ul>
                            )
                        },
                        li: ({ children }) => {
                            return (
                                <li className="text-sm font-normal mb-3">
                                    {children}
                                </li>
                            )
                        },
                    }}
                />
                <div className="categories">
                    <ul className="grid lg:grid-cols-4 md:grid-cols-3 gap-6 m-0 p-0">
                        {data.AllFenceOptions.edges.map((item, index) => {
                            const slug = `fence-options/${slugify(item.node.frontmatter.title, { lower: true })}-toronto`
                            return (
                                <li className="mb-3" key={index}>
                                    <div className="item_box p-5 text-center">
                                        <Link to={`/${slug}`}><GatsbyImage image={getImage(item.node.frontmatter.featuredimage)} alt={item.node.frontmatter.title} className="w-full mb-5 border border-gray-300" /></Link>
                                        <h3 className="uppercase text-base font-normal text-center md:h-24 text-gray-700 mb-6">
                                            <Link to={`/${slug}`} aria-label={item?.node?.frontmatter?.title} className="uppercase text-base font-normal text-center text-gray-700">{item.node.frontmatter.title}</Link>
                                        </h3>
                                        <Link to={`/${slug}`} aria-label={item?.node?.frontmatter?.title} className="font1 bg-white border-b border-gray-400 text-sm text-gray-700 inline-block py-2 px-4 uppercase rounded-md font-normal hover:text-cyan-400">Read More</Link>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
export const Head = (props) => <Seo title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'} path={props.location.pathname} />

export const pageQuery = graphql`
  query FenceQuery{
    markdownRemark(frontmatter: {slug: {eq: "fence-options"}}) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
    AllFenceOptions: allMarkdownRemark(
        filter: {frontmatter: {templateKey: {eq: "fence-cat"}, status: {ne: "Draft"}}}
        sort: {frontmatter: {order: ASC}}
    ) {
        edges {
        node {
            frontmatter {
            templateKey
            title
            featuredimage {
                childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 175, height: 210)
                }
            }
            }
        }
        }
    }
  }
`;
export default FenceOptionsPage
